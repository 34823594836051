import { Formik, ErrorMessage } from "formik";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import treeFormModel from "./FormModel/treeFormModel";
import { validation } from "./FormModel/validationSchema";
import { useMutation, useQueryClient } from "react-query";
import { useQuery } from "react-query";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Modal, Form, ModalBody, Button } from "react-bootstrap";
import { createTree, updateTree } from "../../../services/treeCrud";
import { MoreInfo, TreeProfile, TreeLocation, TreeSensor } from "./steps";
import { formInitialValues, formUpdateValues } from "./FormModel/initialValues";
import { getTreesSpecies } from "@modules/TreeSpecies/services/speciesCrud";
import { styles } from "@components/styles";
import FormError from "@components/Form/FormError";
import ChildTree from "./steps/ChildTree";

const stepAdd = ["tree_form.tree_profile", "tree_form.position", "tree_form.more_info"];
const stepEdit = [
  "tree_form.tree_profile",
  "tree_form.position",
  "tree_form.sensors",
  "tree_form.representTree",
  "tree_form.more_info",
];
const { formField } = treeFormModel;

const renderStepAdd = (steps, values, formValue, setFieldValue, species) => {
  switch (steps) {
    case 0:
      return <TreeProfile formField={formField} setFieldValue={setFieldValue} />;
    case 1:
      return (
        <TreeLocation
          values={values}
          formValue={formValue}
          formField={formField}
          validation={validation}
          setFieldValue={setFieldValue}
        />
      );

    case 2:
      return <MoreInfo formField={formField} species={species} />;
    default:
      return <>NotFound</>;
  }
};
const renderStepEdit = (steps, values, formValue, setFieldValue, species) => {
  switch (steps) {
    case 0:
      return <TreeProfile formField={formField} />;
    case 1:
      return (
        <TreeLocation
          values={values}
          formField={formField}
          validation={validation}
          setFieldValue={setFieldValue}
        />
      );
    case 2:
      return (
        <TreeSensor
          formField={formField}
          values={values}
          formValue={formValue}
          setFieldValue={setFieldValue}
        />
      );
    case 3:
      return <ChildTree formField={formField} values={values} setFieldValue={setFieldValue} />;
    case 4:
      return <MoreInfo formField={formField} species={species} />;
    default:
      return <>NotFound</>;
  }
};

export default function TreeForm({
  show,
  isUpdate,
  setOpenForm,
  formValue,
  setIsUpdate,
  setTreeValues,
}) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const tid = query.get("selectedTree");
  const { data: species } = useQuery("treeSpecies", getTreesSpecies, {
    refetchOnWindowFocus: false,
    retry: false,
  });
  const [error, setError] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = isUpdate
    ? activeStep === stepEdit.length - 1
    : activeStep === stepAdd.length - 1;

  const handleClose = () => {
    setOpenForm(false);
    setIsUpdate(false);
    setActiveStep(0);
  };

  const { isLoading, isError, mutateAsync } = useMutation(
    isUpdate ? "updateTree" : "addTree",
    isUpdate ? updateTree : createTree,
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries("tree", `${tid}`);
        queryClient.invalidateQueries("trees");
        setTreeValues ? setTreeValues(data) : [];
        handleClose();
      },
      onError: (error) => {
        if (error.response && error.response.data) {
          setError(error.response.data);
        } else {
          setError({ message: "An unexpected error occurred" });
        }
      },
    }
  );

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setErrors } = actions;
    const formData = {
      tree_cadastre_no: values.tree_cadastre_no,
      name: values.name,
      species: values.species
        ? {
            name: values.species,
          }
        : null,
      position:
        values.latitude && values.latitude && values.latitude
          ? {
              lat: values.latitude,
              lng: values.longitude,
              alt: values.altitude,
            }
          : null,

      trunk_circumference: values.trunk_circumference,
      crown_diameter: values.crown_diameter,
      phase: parseInt(values.phase),
      height: values.height,
    };
    if (isUpdate) {
      formData["represented_trees"] = values?.represented_trees;
    }
    if (!isLastStep) {
      setActiveStep(activeStep + 1);
      setSubmitting(false);
    } else {
      try {
        if (isUpdate) {
          await mutateAsync({ formData, id: formValue.id });
        } else {
          await mutateAsync(formData);
        }
        setSubmitting(false);
      } catch (error) {
        if (error.response && error.response.data) {
          setErrors(parseBackendErrors(error.response.data));
        } else {
          setErrors({ message: "An unexpected error occurred" });
        }
        setSubmitting(false);
      }
    }
  };

  const parseBackendErrors = (backendErrors) => {
    const formikErrors = {};
    if (backendErrors.position) {
      if (backendErrors.position.lng) {
        formikErrors["position.lng"] = backendErrors.position.lng.join(", ");
      }
      if (backendErrors.position.lat) {
        formikErrors["position.lat"] = backendErrors.position.lat.join(", ");
      }
    }
    return formikErrors;
  };

  // Helper function to get a top-level error message from backend errors
  const getTopLevelErrorMessage = (backendErrors) => {
    if (backendErrors.position) {
      if (backendErrors.position.lng) {
        return `Longitude: ${backendErrors.position.lng.join(", ")}`;
      }
      if (backendErrors.position.lat) {
        return `Latitude: ${backendErrors.position.lat.join(", ")}`;
      }
    }
    return "An unexpected error occurred";
  };

  return (
    <>
      <Modal data-testid="tree-form-modal" onHide={handleClose} show={show} centered>
        <ModalHeader closeButton>
          <h5 className="mb-0">{isUpdate ? `${t("edit")} ${t("tree")}` : `${t("addTree")}`}</h5>
        </ModalHeader>
        <ModalBody>
          <div className={styles.wizardList}>
            {isUpdate
              ? stepEdit.map((step, i) => (
                  <span key={step} className={activeStep == i ? "wizard-active" : ""}>
                    {t(step)}
                  </span>
                ))
              : stepAdd.map((step, i) => (
                  <span key={step} className={activeStep == i ? "wizard-active" : ""}>
                    {t(step)}
                  </span>
                ))}
          </div>
          <Formik
            initialValues={isUpdate ? formUpdateValues(formValue) : formInitialValues}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, values, setFieldValue, errors, touched, isSubmitting }) => {
              return (
                <>
                  <Form data-testid="tree-form" onSubmit={handleSubmit}>
                    <div className="form-container">
                      {isUpdate
                        ? renderStepEdit(activeStep, values, formValue, setFieldValue, species)
                        : renderStepAdd(activeStep, values, formValue, setFieldValue, species)}
                    </div>
                    <div className={styles.formBody}>
                      {activeStep !== 0 && (
                        <Button
                          type="button"
                          variant="btn btn-outline-primary"
                          onClick={() => setActiveStep((step) => step - 1)}
                        >
                          {t("button.back")}
                        </Button>
                      )}

                      <button type="submit" className="btn btn-outline-primary ms-auto">
                        {isLastStep
                          ? isUpdate
                            ? isSubmitting
                              ? t("button.updating")
                              : t("button.update")
                            : isSubmitting
                            ? t("button.saving")
                            : t("button.save")
                          : t("button.next")}
                      </button>
                    </div>
                    {isError && error && (
                      <div className={styles.validationError}>
                        <FormError title={getTopLevelErrorMessage(error) || t("error.form")} />
                      </div>
                    )}
                    <ErrorMessage
                      name="latitude"
                      component="div"
                      className={styles.validationError}
                    />
                  </Form>
                </>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}
