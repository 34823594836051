import Skeleton from "./Skeleton";
import { useQueries } from "react-query";
import useFilter from "@hooks/useFilter";
import { styles } from "@components/styles";
import * as d3 from "d3-scale-chromatic";
import { Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { constants } from "../context/constants";
import { useNavigate, useLocation } from "react-router-dom";
import { TriangleUpIcon } from "@components/icons";
import { DataScienceContext } from "../context/DataScienceContext";
import React, { useState, useContext, useEffect, useMemo } from "react";
import { getIrrigationArea } from "@modules/IrrigationArea/services/areaCrud";

function TreeList() {
  const { pid, filterState, filterDispatch, treeLineColor, setTreeLineColor } =
    useContext(DataScienceContext);
  const { trees, areasId, treesId, tree_sensor_pairs, hardwareSerial } = filterState;
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const [filterTerm, setFilterTerm] = useState("");
  const [open, setOpen] = useState(true);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const { t } = useTranslation();
  const colorsList1 = d3.schemeAccent;
  const colorsList2 = d3.schemeCategory10;
  const colorsList3 = d3.schemeDark2;
  const colorsList = colorsList2.concat(colorsList1, colorsList3);
  var tid = query.get("trees");
  if (tid) {
    const tid_list = [...new Set(tid.split(","))];
    tid = tid_list.join(",");
  }

  const tsid = query.get("treeSensors");

  const filteredTrees = useMemo(
    () => useFilter({ filterTerm, filterItem: trees }),
    [filterTerm, trees]
  );

  //1. fetch the tree related to particular project
  const results = useQueries(
    areasId.map((areaId) => {
      return {
        queryKey: ["ds_trees", areaId],
        queryFn: () => getIrrigationArea(areaId),
        staleTime: 1000 * 60 * 60,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          data.tree_set.length !== 0 &&
            filterDispatch({
              type: constants.SET_PROJECT_TREE_LIST,
              payload: data.tree_set,
            });
        },
      };
    })
  );
  //set color to line on the base of hardware serials selected
  function getColor() {
    // let colors = tids?.map((c) => colorsList[tids.indexOf(c)]);
    let colors = {};
    for (let i = 0; i < treesId.length; i++) {
      const tree = +treesId[i];
      const color = colorsList[i];
      colors[tree] = color;
    }
    if (treesId && colors && !Object.values(colors).some((item) => item === undefined)) {
      setTreeLineColor(colors);
    }
  }

  const isLoading = results.some((a) => a.isLoading);
  const isFetching = results.some((a) => a.isFetching);

  useEffect(() => {
    filterDispatch({ type: constants.SET_PROJECT_TREE_LIST, payload: [] });
    results.forEach((result) => result.refetch());
  }, [areasId]);

  /* 2. load the treesid from url and set to treesIds.This function runs once the page reload. 
  This will persist the selected or previously checked tree. */
  useEffect(() => {
    if (tid) {
      const treeIds = tid.split(",").map(Number);
      filterDispatch({ type: constants.SET_CHECKED_TREES_ID, payload: treeIds });
      const allTreesSelected = filteredTrees.every((tree) => treeIds.includes(tree.id));
      setSelectAllChecked(allTreesSelected);
    } else {
      // If no trees are specified, ensure the "Select All" checkbox is unchecked
      setSelectAllChecked(false);
    }
  }, [tid, filteredTrees]);

  useEffect(() => {
    if (tsid) {
      const treeid = Array.from(
        new Set(
          tsid.split(",").map((tsp) => {
            return tsp.split(" ")[0];
          })
        )
      ).join(",");
      const treeIds = treeid.split(",").map(Number);
      filterDispatch({ type: constants.SET_CHECKED_TREES_ID, payload: treeIds });
    }
  }, []);

  //3. set url based on tree checked
  useEffect(() => {
    getColor();
    if (areasId.length > 0) {
      if (treesId.length === 0) {
        navigate(`/project/${pid}/datascience?areas=${areasId.join(",")}`);
      } else {
        navigate(
          `/project/${pid}/datascience?areas=${areasId.join(",")}&trees=` + treesId.join(",")
        );
      }
    }
  }, [treesId]);

  useEffect(() => {
    // Update the select all checkbox based on whether any trees are initially selected
    setSelectAllChecked(
      treesId.length > 0 && filteredTrees.every((tree) => treesId.includes(tree.id))
    );

    //const allChecked = filteredTrees.every((tree) => treesId.includes(tree.id));
    //setSelectAllChecked(allChecked);
  }, [filteredTrees, treesId]);

  //4. This function used to set the value for tree search on key change to input field.
  function updateFilterHandler(event) {
    setFilterTerm(event.target.value);
  }

  //5. Check and uncheck functionalities to set and filter tree from tree list
  const handleChange = (props) => {
    const { e, tree } = props;
    const isChecked = e.target.checked;
    const tree_sensors = tree.installations.map((installation) => {
      const sensor = installation.sensor;
      return {
        tree: tree.id,
        sensor: sensor.id,
        sensor_name: sensor.name,
        hardware_serial: sensor.hardware_serial,
        tree_name: tree.name,
      };
    });

    // Determine new list of checked trees based on current action
    let newCheckedTrees = isChecked
      ? [...treesId, tree.id] // Add tree id if checked
      : treesId.filter((id) => id !== tree.id); // Remove tree id if unchecked

    filterDispatch({ type: constants.SET_CHECKED_TREES_ID, payload: newCheckedTrees });

    if (isChecked) {
      filterDispatch({ type: constants.SET_TREES_ID, payload: [tree.id] });
      tree_sensors?.map((sensor) => {
        filterDispatch({
          type: constants.SET_HARDWARE_SERIAL,
          payload: [[sensor.sensor], [sensor]],
        });
      });
    } else {
      let filteredTree = Array.from(new Set(treesId.filter((id) => id !== tree.id)));
      filterDispatch({ type: constants.SET_CHECKED_TREES_ID, payload: filteredTree });
      const tlist = tree.installations.map((installation) => installation.sensor.hardware_serial);
      const filteredSerial = hardwareSerial.filter((f) => !tlist.includes(f));
      const filtered_tree_sensors_pairs = tree_sensor_pairs.filter((item) => item.tree !== tree.id);
      filterDispatch({
        type: constants.SET_FILTERED_HARDWARE_SERIAL,
        payload: [filteredSerial, filtered_tree_sensors_pairs],
      });
    }
    // Update Select All Checkbox State
    // Check if all trees are now checked or not
    const allChecked = filteredTrees.every((tree) => newCheckedTrees.includes(tree.id));
    setSelectAllChecked(allChecked);
  };

  // 6.handle all select checkbox
  const handleSelectAll = (props) => {
    const { e, filteredTrees } = props;
    const isChecked = e.target.checked;

    // Set the checked state for all or none
    setSelectAllChecked(isChecked);

    if (isChecked) {
      const ids = filteredTrees.map((tree) => tree.id);

      filterDispatch({ type: constants.SET_TREES_ID, payload: [ids] });
    } else {
      filterDispatch({ type: constants.SET_CHECKED_TREES_ID, payload: [] });
      filterDispatch({ type: constants.SET_FILTERED_HARDWARE_SERIAL, payload: [[], []] });
    }
  };

  return (
    <div className="me-3">
      <div className="d-flex pe-2 justify-content-between">
        <div
          className={styles.dsFilterTitle}
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          <TriangleUpIcon open={open} /> <span className=" ds-filter-title">{t("trees")}</span>
        </div>
        <div>
          <input
            type="checkbox"
            checked={selectAllChecked}
            onChange={(e) => handleSelectAll({ e, filteredTrees })}
          />
        </div>
      </div>
      <Collapse in={open}>
        <div>
          <div className="py-2 pe-2">
            <input
              type="text"
              className="form-control filter-search"
              placeholder="Search tree"
              onChange={updateFilterHandler}
            />
          </div>

          {isLoading | isFetching ? (
            <>
              {[1, 2, 3, 4, 5].map((a, i) => (
                <Skeleton key={i} />
              ))}
            </>
          ) : (
            <ul className="p-0 pe-2  filter__list" id="example-collapse-text">
              {filteredTrees?.map((tree, i) => (
                <li key={i} className={`filter__items  gap-1 d-flex justify-content-between ps-1`}>
                  {tree.name}
                  {" " + treeLineColor[tree.id] && Object.keys(treeLineColor).includes(tree.id)}
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      handleChange({ e, tree });
                    }}
                    style={{
                      background:
                        treeLineColor && Object.keys(treeLineColor).map(Number).includes(+tree.id)
                          ? treeLineColor[tree.id]
                          : "#fff",
                      border: "1px solid #ccc",
                      outline:
                        treeLineColor && Object.keys(treeLineColor).map(Number).includes(+tree.id)
                          ? `1px solid ${treeLineColor[tree.id]}`
                          : "1px solid #388090",
                    }}
                    checked={tid && tid.split(",").map(Number).includes(tree.id) ? "checked" : ""}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </Collapse>
    </div>
  );
}

export default React.memo(TreeList);
