import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { QrReader } from "react-qr-reader";
import { QrScanIcon } from "@components/icons";
import { useSelector } from "react-redux";

export default function QRForm({ data, setData }) {
  const qrRef = useRef(null);
  const { t } = useTranslation();
  const { mobile } = useSelector((state) => state.mobile);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setData("");
    setShow(false);
  };
  const onResult = (result, error) => {
    if (result) {
      setData(result?.text);
      setShow(false);
    }

    if (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button
        data-testid="qr-button"
        className="btn btn-primary"
        style={{ marginTop: "5px" }}
        onClick={() => setShow(true)}
      >
        <QrScanIcon title="QR Scanner" />
      </Button>
      <Modal show={show} onHide={handleClose} className="m-0">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0 m-0 ">
          <div>
            <div
              className="text-center w-100 position-absolute top-0 py-lg-4 py-5"
              style={{ zIndex: "9" }}
            ></div>
            <QrReader
              onResult={onResult}
              className="Qrviewcontainer"
              constraints={mobile ? { facingMode: "environment" } : undefined}
            />
            <QRBox />

            <span className="text-white qrText">{t("align_qr_code_within_the_frame")}</span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

function QRBox() {
  return (
    <div className="qr-scanner">
      <div className="box">
        <div className="line"></div>
        <div className="angle"></div>
      </div>
    </div>
  );
}
