import { constants } from "./constants";
const {
  SET_CHECKED_TREES_ID,
  SET_PROJECT_TREE_LIST,
  SET_PROJECT_AREAS,
  SET_TREES_ID,
  SET_HARDWARE_SERIAL,
  SET_CHECKED_AREAS_ID,
  SET_FILTERED_HARDWARE_SERIAL,
  SET_SENSORS,
  SET_AREAS_ID,
} = constants;
const filterReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_PROJECT_AREAS:
      return { ...state, areas: payload };

    case SET_PROJECT_TREE_LIST: {
      const trees = state.trees;
      // const areas = state.areas;
      const finalTrees = payload.length !== 0 ? trees.concat(payload) : [];
      // const finalAreas = payload.length !== 0 ? areas.concat(payload) : [];
      return { ...state, trees: finalTrees };
    }
    case SET_AREAS_ID: {
      const areasId = state.areasId;
      const p = new Set(payload.flat());
      return { ...state, areasId: [...areasId, ...p].flat() };
    }
    case SET_TREES_ID: {
      // const treesId = new Array(new Set(state.treesId));
      const treesId = state.treesId;
      const p = new Set(payload.flat());
      // console.log("TREEEEES23", treesId);
      return { ...state, treesId: [...treesId, ...p].flat() };
    }
    case SET_CHECKED_AREAS_ID: {
      return { ...state, areasId: payload };
    }
    case SET_CHECKED_TREES_ID: {
      state.treesId = [];
      // console.log("TREEEEES", payload);
      // payload = [...new Set(payload)].filter(Boolean);
      // console.log("TREEEEES", payload);
      return { ...state, treesId: payload };
    }
    case SET_HARDWARE_SERIAL: {
      // add hardware_serial
      // const tree_sensor_pairs = [...new Set(state.tree_sensor_pairs)];
      // const hardwareSerial = [...new Set(state.hardwareSerial)];
      const tree_sensor_pairs = state.tree_sensor_pairs;
      const hardwareSerial = state.hardwareSerial;
      const hs = new Set(payload[0].flat());
      const ts = new Set(payload[1].flat());
      return {
        ...state,
        hardwareSerial: [...hardwareSerial, ...hs].flat(),
        tree_sensor_pairs: [...tree_sensor_pairs, ...ts],
      };
    }
    case SET_FILTERED_HARDWARE_SERIAL: {
      // set hardware_serial
      return { ...state, hardwareSerial: payload[0], tree_sensor_pairs: payload[1] };
    }
    case SET_SENSORS: {
      const sensors = state.sensors;
      const tree_sensor_pairs_tree = state.tree_sensor_pairs_tree;
      const final_tree_sensor_pairs =
        payload.length !== 0
          ? payload[1].length !== 0
            ? tree_sensor_pairs_tree.concat(payload[1])
            : []
          : [];
      const finalSensors =
        payload.length !== 0 ? (payload[0].length !== 0 ? sensors.concat(payload[0]) : []) : [];
      return {
        ...state,
        sensors: finalSensors,
        tree_sensor_pairs_tree: final_tree_sensor_pairs,
      };
    }
    default:
      return state;
  }
};

export default filterReducer;
