import * as L from "leaflet";

import { GREEN, YELLOW, RED, GREY, BLUE, DARKGREEN } from "./colors";

const parentTreeHeight = 50;
const parentTreeWidth = 50;
const parentTreeSVG = (health) => {
  let color;
  let displayWIFI = "none";
  let displayTree = "true";
  switch (health) {
    case 0:
      color = GREEN;
      break;
    case 1:
      color = YELLOW;
      break;
    case 2:
      color = RED;
      break;
    case 3:
      color = GREY;
      displayWIFI = "true";
      displayTree = "none";
      break;
    case 4:
      color = BLUE;
      break;
  }
  return `<svg
  width=${parentTreeWidth}
  height=${parentTreeHeight}
  id="erlojsG3bX71"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 256 256"
  shapeRendering="geometricPrecision"
  textRendering="geometricPrecision"
>
  <path
    id="rim"
    path
    d="M98.135837,214.416055L68.292893,181.492368C37.01664,146.789686,27.02671,133.35683,27.02671,100c0-55.22847,44.77153-100,100-100s100,44.77153,100,100c0,33.35683-18.962333,56.258737-41.43629,81.06632v0l-58.5637,65.21999-28.890883-31.870255Z"
    fill="#999"
    strokeWidth="0"
  />
  <path
    id="mainColor"
    path
    d="M37.01663,100.36192c0-50.20788,40.47013-90.9094,90.39256-90.9094s90.39256,40.70152,90.39256,90.9094c0,33.03695-22.20292,55.896817-43.73004,77.87566l-47.04499,53.53033-47.5799-54.13898v0c-22.332399-26.344299-42.43018-44.64686-42.43018-77.26701h-.00001Z"
    fill=${color}
    strokeWidth="0"
  />
  <g id="tree" transform="matrix(1.506921 0 0 1.549543 60.18855 37.285657)" opacity="0.67" display=${displayTree}>
    <path
      d="M51.865,1.523c2.639.961,4.659,2.757,6.26,5.115c5.117-.776,10.301.912,13.976,4.587s5.364,8.857,4.588,13.975c4.168,3.07,6.638,7.929,6.638,13.126c0,5.393-2.644,10.387-7.091,13.437-.76,2.672-2.184,5.104-4.135,7.055-3.134,3.134-7.323,4.754-11.539,4.754-3.088,0-6.19-.869-8.902-2.649l.549-.836-10.447-1.312c0,0,0,0,0,0l-.216.977c-.422-.094-.843-.204-1.256-.329-3.807,3.412-8.875,4.69-13.641,3.882.009-.082.048-.316.054-.379C21.64,59.31,16.967,51.451,16.924,45c-.041-6.216,2.696-12.469,7.394-16.172-.92-6.117,1.123-11.997,4.951-16.687C33.23,7.289,39.707,5.252,45.685,6.579c.248-2.89,3.212-4.035,6.18-5.056"
      fill="#fff"
    />
    <path
      d="M26.702,62.925C21.64,59.31,17.809,51.551,17.767,45.1c-.041-6.216,2.718-12.047,7.415-15.75-.92-6.117.952-12.328,5.086-16.751c4.133-4.423,9.991-6.482,15.793-5.591c1.564-2.275,3.552-4.128,5.804-5.483C49.745,0.537,47.41,0,44.999,0C39.802,0,34.943,2.47,31.873,6.639c-5.117-.777-10.3.912-13.975,4.587s-5.364,8.857-4.587,13.975c-4.169,3.07-6.638,7.929-6.638,13.126c0,5.393,2.644,10.387,7.091,13.437.759,2.67,2.183,5.103,4.135,7.055c2.456,2.456,5.534,3.942,8.749,4.487.008-.083.048-.319.054-.381Z"
      fill="#fff"
    />
    <path
      d="M57.523,54.976l-3.031-2.947c-.387-.375-.999-.378-1.388-.007l-3.865,3.69v-14.823c0-.552-.447-1-1-1h-4.275c-.552,0-1,.448-1,1v7.186l-4.745-4.919c-.377-.391-1.063-.391-1.439,0L34.26,45.77c-.369.382-.374.986-.013,1.375l6.515,7.007v34.848c0,.553.448,1,1,1h6.477c.553,0,1-.447,1-1v-24.71l8.276-7.872c.197-.188.309-.447.311-.719.002-.273-.108-.533-.303-.723Z"
      fill="#fff"
    />
  </g>
  <g id="connection" transform="translate(0 0.000001)" display=${displayWIFI}>
    <ellipse
      rx="18.797286"
      ry="17.351341"
      transform="translate(129.176355 164.141026)"
      strokeWidth="0"
      fill="#FFFFFF"
    />
    <path
      d="M129.36054,119.14103c16.25838,0,30.50295,8.6222,38.41058,21.54349l-7.45231,10.60011c-5.44372-11.64029-17.25923-19.70458-30.95826-19.70458s-25.51455,8.06429-30.95826,19.70459l-7.45231-10.60011c7.90763-12.92129,22.15219-21.5435,38.41058-21.5435h-.00002ZM128,61.54306c28.89123,0,55.64583,9.42011,77.52525,25.44029l-9.92642,14.11926c-19.3792-13.74755-42.48067-22.33615-67.00802-22.33615-24.36757,0-46.89883,8.47708-65.65697,22.06816L52.28173,85.68314c21.53823-15.22845,47.6176-24.14008,75.71828-24.14008h-.00001Zm1.36053,31.89993c21.85166,0,41.64331,8.48209,56.00918,22.20944l-7.97746,11.34707c-12.81653-13.17218-30.39022-22.1805-47.79342-22.1805-17.5115,0-35.22879,9.12079-48.09677,22.4269l-8.1507-11.59349c14.36587-13.72734,34.15752-22.20943,56.00917-22.20943v.00001Z"
      strokeWidth="0"
      fill="#FFFFFF"
    />
    />
    <rect
      width="17.539893"
      height="138.76604"
      rx="0"
      ry="0"
      transform="matrix(.598652 0.594577-.90503 0.911231 186.447039 30.65698)"
      strokeWidth="0"
      fill="#FFFFFF"
    />
  </g>
</svg>`;
};

export const parentTreeIcon = (health) => {
  return L.divIcon({
    html: parentTreeSVG(health),
    className: "parentTreeIcon",
    iconSize: [parentTreeWidth, parentTreeHeight],
    shadowSize: [0, 0],
    iconAnchor: [parentTreeWidth / 2 - 1, parentTreeHeight],
    popupAnchor: [0, 0],
  });
};

const childTreeHeight = 16;
const childTreeWidth = 16;
const childTreeSVG = (health) => {
  let color;
  switch (health) {
    case 0:
      color = GREEN;
      break;
    case 1:
      color = YELLOW;
      break;
    case 2:
      color = RED;
      break;
    case 3:
      color = GREY;
      break;
    case 4:
      color = BLUE;
      break;
  }
  return `
  <svg
    width=${childTreeWidth}
    height=${childTreeHeight}
    id="childTree"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
  >
    <ellipse rx="8" ry="8" transform="translate(8 8)" fill=${color} stroke-width="0" />
  </svg>`;
};

export const childTreeIcon = (health) => {
  return L.divIcon({
    html: childTreeSVG(health),
    className: "childTreeIcon",
    iconSize: [childTreeWidth, childTreeHeight],
    shadowSize: [0, 0],
    iconAnchor: [childTreeWidth / 2, childTreeHeight / 2], // relative to top left
    popupAnchor: [0, parentTreeHeight / 2], // relative to iconAnchor
  });
};
const noSensorTreeHeight = 8;
const noSensorTreeWidth = 8;
const noSensorTreeSVG = `<svg
    id="normalTree"
    opacity="0.5"
  >
    <circle cx="${noSensorTreeWidth / 2}" cy="${noSensorTreeHeight / 2}" r="${
  noSensorTreeWidth / 2
}" stroke-width="0" fill=${DARKGREEN} />
  </svg>`;

export const noSensorTreeIcon = () => {
  return L.divIcon({
    html: noSensorTreeSVG,
    // iconUrl: leaf,
    className: "noSensorTreeIcon",
    // shadowSize: [0, 0],
    iconAnchor: [noSensorTreeWidth / 2, noSensorTreeHeight / 2], // relative to top left
    popupAnchor: [0, parentTreeHeight / 2], // relative to iconAnchor
  });
};
