import React, { useEffect, useState } from "react";
import { DropdownButton } from "react-bootstrap";
import TablePagination from "./Pagination/Pagination";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { useAsyncDebounce, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { AngleDownIcon, SearchIcon } from "./icons";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

function ReactTable({ columns, data, onRowClicked, activeId, moreFilter, pagesize = 10, size }) {
  let pageindex = 0;
  let [pageS, setPageS] = useState(pagesize); // console.log("DATA", data);
  const index = data.findIndex((entry) => entry.id === +activeId);
  if (index > 0) {
    pageindex = parseInt(index / pageS);
  }
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pagesize, pageIndex: pageindex },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    setPageSize,
    previousPage,
    state: { pageSize, globalFilter, pageIndex, sortBy },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;
  useEffect(() => {
    setPageS(pageSize);
  }, [pageSize]);
  useEffect(() => {
    gotoPage(0); // Go to the first page whenever sorting changes
  }, [sortBy]);

  return (
    <div className="table-wrapper">
      <div className="table-wrapper__filter ">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <DropdownButton
          title={
            <div className="d-flex gap-2" title="Page size">
              <span>{pageSize}</span>
              <AngleDownIcon />
            </div>
          }
          variant="btn-transparent"
        >
          {size.map((pageSize, i) => (
            <DropdownItem
              key={i}
              className="d-flex align-items-center gap-2 px-2 py-1"
              onClick={() => setPageSize(pageSize)}
            >
              {pageSize}
            </DropdownItem>
          ))}
        </DropdownButton>
        {moreFilter}
      </div>
      <div className="table-wrapper__container">
        <div className="table-wrapper__container__inner ">
          <table {...getTableProps()}>
            <thead>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup, i) => (
                  // Apply the header row props
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column, i) => (
                        // Apply the header cell props
                        <th
                          key={i}
                          {...column.getHeaderProps([
                            column.getSortByToggleProps(),
                            {
                              style: column.style,
                              className: column.className,
                            },
                          ])}
                        >
                          <div>
                            {
                              // Render the header
                              column.render("Header")
                            }
                            {
                              <span>
                                {column.disableSortBy ? (
                                  []
                                ) : column.isSorted ? (
                                  column.isSortedDesc ? (
                                    // <SortDownIcon />
                                    <FontAwesomeIcon icon={faSortDown} />
                                  ) : (
                                    // <SortUpIcon />
                                    <FontAwesomeIcon icon={faSortUp} />
                                  )
                                ) : (
                                  <FontAwesomeIcon icon={faSort} />
                                )}
                              </span>
                            }
                          </div>
                        </th>
                      ))
                    }
                  </tr>
                ))
              }
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
              {
                // Loop over the table rows
                page.map((row, i) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr
                      className={+activeId == row.original.id ? "table-wrapper__active" : ""}
                      key={i}
                      {...row.getRowProps({
                        onClick: () => onRowClicked && onRowClicked(row),
                      })}
                    >
                      {
                        // Loop over the rows cells
                        row.cells.map((cell, i) => {
                          // Apply the cell props
                          return (
                            <td key={i} {...cell.getCellProps()}>
                              {
                                // Render the cell contents
                                cell.render("Cell")
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
          <TablePagination
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            pageSize={pageSize}
          />
        </div>
      </div>
    </div>
  );
}

export default ReactTable;

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const { t } = useTranslation();
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="table-wrapper__filter__searchbar">
      <input
        className="form-control"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} ${t("records")}...`}
        style={{ maxWidth: "200px" }}
      />
      <SearchIcon top="0rem" left="0.3rem" width={"30px"} />
    </div>
  );
}
