import moment from "moment";
import { useParams } from "react-router-dom";
import filterReducer from "./FilterReducer";
import React, { useReducer, useState } from "react";
import { filterInitialState } from "./initialStates";

export const DataScienceContext = React.createContext();

function DataScienceProvider({ children }) {
  const [filterState, filterDispatch] = useReducer(filterReducer, filterInitialState);
  const [draggableClicked, setDraggableClicked] = useState(false);
  const [activeMean, setActiveMean] = useState(false);
  const [activeHealthData, setActiveHealthData] = useState(false);
  const [positionLeft, setPositionLeft] = useState(false);
  const [positionRight, setPositionRight] = useState(false);
  const [dragItemLeft, setDragItemLeft] = useState();
  const [dragItemRight, setDragItemRight] = useState();
  const [startDate, setStartDate] = useState();
  const [activeRain, setActiveRain] = useState(false);
  const [lYaxis, setLYaxis] = useState([]);
  const [rYaxis, setRYaxis] = useState([]);
  const [lYaxisMean, setLYaxisMean] = useState([]);
  const [rYaxisMean, setRYaxisMean] = useState([]);
  const [healthData, setHealthData] = useState([]);
  const [endDate, setEndDate] = useState();
  const [lineColor, setLineColor] = useState({});
  const [treeLineColor, setTreeLineColor] = useState({});
  const [activeDate, setActiveDate] = useState(2);
  const [activeSensors, setActiveSensors] = useState([]);
  const [strokewidth, setStrokeWidth] = React.useState({
    serial: 1,
  });

  const { pid } = useParams();
  // console.log("FS in DS Context:", filterState);R

  React.useEffect(() => {
    setStartDate(moment().subtract(3, "weeks").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  }, []);

  return (
    <DataScienceContext.Provider
      value={{
        filterState,
        filterDispatch,
        pid,
        lYaxis,
        rYaxis,
        setLYaxis,
        setRYaxis,
        lYaxisMean,
        rYaxisMean,
        setLYaxisMean,
        setRYaxisMean,
        dragItemLeft,
        dragItemRight,
        setDragItemLeft,
        setDragItemRight,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        lineColor,
        treeLineColor,
        setLineColor,
        setTreeLineColor,
        setPositionLeft,
        setPositionRight,
        setActiveRain,
        activeRain,
        strokewidth,
        setStrokeWidth,
        setActiveDate,
        activeDate,
        healthData,
        setHealthData,
        activeMean,
        setActiveMean,
        positionLeft,
        positionRight,
        activeHealthData,
        setActiveHealthData,
        setDraggableClicked,
        draggableClicked,
        activeSensors,
        setActiveSensors,
      }}
    >
      {children}
    </DataScienceContext.Provider>
  );
}

export default DataScienceProvider;
