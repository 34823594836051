import "./styles/style.scss";
import { I18nextProvider } from "react-i18next";
import React from "react";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import i18n from "./translation/i18n";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// import { steps, step, setCurrentStep } from "@modules/Tour/steps";
// import { TourProvider } from "@reactour/tour";
require("typeface-poppins");

const { PUBLIC_URL } = process.env;
console.log(process.env);

const container = document.getElementById("ts_root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter basename={PUBLIC_URL}>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
  // eslint-disable-next-line no-undef
);
