import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useChoices from "@hooks/useChoices";
import { useTranslation } from "react-i18next";
import { Col, Row, Button, Card } from "react-bootstrap";
import { useQueryClient } from "react-query";
import {
  MapMarkerAltIcon,
  MenuAlt1Icon,
  LineChartIcon,
  CloseIcon,
  AngleLeftIcon,
  AngleRightIcon,
  AddIcon,
} from "@components/icons";
import ServerError from "@components/Error/ServerError";
import PageNotFound from "@components/Error/PageNotFound";
import { MobileBreadcrumb } from "@components/Breadcrumb";
import ExportSensor from "@modules/ExportSensor/ExportSensor";
import Spinner from "@components/Spinner/Spinner";
import { getTree, getTreeImages } from "../services/treeCrud";
import { MaintenanceForm, MaintenanceMessage } from "./Maintenance";
import Tree from "./Tree";
import TreeImageForm from "./Form/AddForm/TreeImageForm";
import TreeForm from "./Form/AddForm";

export default function TreeDetail({ pid, aid, treeId, type, setOpenTreeDetail, map }) {
  const query = new URLSearchParams(useLocation().search);
  var tid;
  if (treeId) {
    tid = treeId;
  } else {
    tid = query.get("selectedTree");
  }
  const { t } = useTranslation();
  const [openForm, setOpenForm] = useState(false);
  const [openMaintenanceForm, setOpenMaintenanceForm] = useState(false);
  const [isMaintenanceUpdate, setIsMaintenanceUpdate] = useState(false);
  const [maintenance, setMaintenanceItem] = useState(null);
  const [treeImages, setTreeImages] = useState([]);
  const [leafImages, setLeafImages] = useState([]);
  const [treeIndex, setTreeIndex] = useState(0);
  const [leafIndex, setLeafIndex] = useState(0);
  const [openImageForm, setOpenImageForm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [locations] = useChoices("location");
  const [phases] = useChoices("growth_phase");
  const [healthState] = useChoices("health_state");
  const [serials, setSeraials] = useState([]);
  const { mobile } = useSelector((state) => state.mobile);
  let areaId = aid;
  const queryClient = useQueryClient();

  //retriving tree details with treeid
  const {
    data: tree,
    isLoading,
    isError,
    error,
  } = useQuery(["tree", tid], () => getTree(tid), {
    refetchOnWindowFocus: false,
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // delay between retries in milliseconds
    staleTime: 1000 * 60 * 60,
    onSuccess: (data) => {
      data?.sensors.length !== 0 && setSeraials(data.sensors?.map((a) => a.hardware_serial));
    },
  });
  const [prevTreeId, setPrevTreeId] = useState(null);
  if (aid == null) {
    areaId = tree?.area?.id;
  }

  useQuery(["treeimages", tid], () => getTreeImages(tid), {
    refetchOnWindowFocus: false,
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // delay between retries in milliseconds
    staleTime: 1000 * 60 * 60,
    onSuccess: (data) => {
      if (data?.treeimage_set.length !== 0) {
        const treeImages = data.treeimage_set?.filter((a) => a.tree_image);
        const leafImages = data.treeimage_set?.filter((a) => a.leaf_image);
        setTreeImages(treeImages);
        setTreeIndex(treeImages.length - 1);
        setLeafImages(leafImages);
        setLeafIndex(leafImages.length - 1);
        tid != prevTreeId
          ? queryClient.invalidateQueries("treeimages", prevTreeId) && setPrevTreeId(tid)
          : [];
      } else {
        const treeImages = [];
        const leafImages = [];
        setTreeImages(treeImages);
        setTreeIndex(0);
        setLeafImages(leafImages);
        setLeafIndex(0);
      }
    },
  });

  const ref = useRef(null);
  const onScroll = () => {
    const scrollTop = ref.current.scrollTop;
    // eslint-disable-next-line no-undef
    const element = document.getElementById("message-box");
    if (scrollTop > 500) {
      element.classList.add("sticky-top");
    } else {
      element.classList.remove("sticky-top");
    }
  };

  const handleFormModel = () => {
    setIsUpdate(true);
    setOpenForm(true);
  };

  const hardwareSerial = tree?.sensors?.map((t) => t.hardware_serial);

  if (isLoading) return <Spinner height={"73vh"} />;
  if (isError && error.response && error.response.status === 404) return <PageNotFound />;
  if (isError) return <ServerError />;

  const sensors = tree.installations.map((installation) => installation.sensor);
  return (
    <>
      {mobile && (
        <>
          <MobileBreadcrumb
            editButton={
              <Button data-testid="tree-detail-edit" onClick={handleFormModel}>
                {t("edit")}
              </Button>
            }
          />
        </>
      )}
      <div
        ref={ref}
        className="treeDetail position-relative"
        style={{
          height: "80vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div
          className="top-0  position-sticky "
          style={{ zIndex: "99", background: "white", padding: "0 12px", height: "55px" }}
        >
          {type == "map" && (
            <CloseIcon
              onClick={() => {
                setOpenTreeDetail(null);
                if (map && tree.position) map.flyTo(tree.position, map.getZoom("18"));
              }}
            />
          )}
          <div
            className="d-flex justify-content-end align-items-center  w-100   "
            style={{ zIndex: "1" }}
          >
            <div className="d-flex align-items-center gap-2">
              {pid && tree.position && (
                <>
                  {type != "map" && (
                    <Link
                      to={
                        `/project/${pid}/map?area=${areaId}&selectedTree=${tid}` +
                        `&lat=${tree?.position.lat}` +
                        `&lngs=${tree?.position.lng}`
                      }
                      target="blank"
                      style={{
                        width: "35px",
                        background: "#eee",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "3px",
                        padding: "4px",
                      }}
                      title="View on map"
                    >
                      <MapMarkerAltIcon width={"20px"} />
                    </Link>
                  )}
                  {type == "map" && (
                    <Link
                      to={`/project/${pid}/list?selectedTree=${tree?.id}`}
                      target="blank"
                      style={{
                        width: "35px",
                        background: "#eee",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "3px",
                        padding: "4px",
                      }}
                      title="View on map"
                    >
                      <MenuAlt1Icon width={"20px"} />
                    </Link>
                  )}

                  <Link
                    to={
                      `/project/${pid}/datascience?areas=${areaId}&trees=${tid}&sensors=` +
                      hardwareSerial.join(",")
                    }
                    target="blank"
                    style={{
                      width: "35px",
                      background: "#eee	",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "3px",

                      padding: "4px",
                    }}
                    title="View on chart"
                  >
                    <LineChartIcon width={"25px"} />
                  </Link>
                </>
              )}
              <div className="d-flex align-items-center gap-2 clickable" title="Export to excel">
                {tree.installations && !tree.installations.length == 0 && (
                  // TODO: change tree sensors to sensors from tree installations but with necessary installation dates to stop download
                  <ExportSensor name={tree.name} serials={serials} sensors={sensors} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: "white",
            padding: "0 12px",
          }}
        >
          {" "}
          <div>
            <h4 className="mb-3">{tree?.name}</h4>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Tree tree={tree} />
          </div>
          <Row data-testid="tree-detail-row" className="g-0">
            <Col lg={6} className="pe-lg-1">
              <ul className="mb-0 list-group">
                <DetailListItem
                  title="tree_cadastre_no"
                  value={tree && tree.tree_cadastre_no ? tree.tree_cadastre_no : ""}
                />
                <DetailListItem
                  title="location"
                  value={
                    {
                      1: locations && locations[0].value,
                      2: locations && locations[1].value,
                    }[tree.location]
                  }
                />
                <DetailListItem title="height" value={tree?.height ? tree.height + " m" : ""} />
                <DetailListItem
                  title="crown_diameter"
                  value={tree?.crown_diameter ? tree.crown_diameter + " m" : ""}
                />
                <DetailListItem
                  title="phase"
                  value={
                    {
                      1: phases && phases[0].value,
                      2: phases && phases[1].value,
                    }[tree.phase]
                  }
                />
              </ul>
            </Col>
            <Col lg={6} className="ps-lg-1">
              <ul className="mb-0 list-group">
                <DetailListItem
                  title="treespecies"
                  value={
                    tree && tree.species ? `${tree.species.name} | ${tree.species.de_name}` : ""
                  }
                />
                <DetailListItem
                  title="trunk_circumference"
                  value={tree && tree.trunk_circumference ? tree.trunk_circumference + " cm" : ""}
                />
                <DetailListItem
                  title="latitude"
                  value={tree && tree.position ? Number(tree.position.lat).toFixed(5) : ""}
                />
                <DetailListItem
                  title="longitude"
                  value={tree && tree.position ? Number(tree.position.lng).toFixed(5) : ""}
                />
                <DetailListItem
                  title="health_state"
                  value={
                    {
                      0: healthState && healthState[0].value,
                      1: healthState && healthState[1].value,
                      2: healthState && healthState[2].value,
                      3: healthState && healthState[3].value,
                      4: healthState && healthState[4].value,
                    }[tree.health_state]
                  }
                />
              </ul>
            </Col>
          </Row>
          <hr style={{ borderTop: "1px dashed rgb(221, 221, 221)" }} />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>{t("maintenances")}</h3>
            <Button onClick={() => setOpenMaintenanceForm(!openMaintenanceForm)}>
              <AddIcon />
              {" " + t("add_maintenance")}{" "}
            </Button>
          </div>
          <MaintenanceMessage
            tree={tree}
            setIsUpdate={setIsMaintenanceUpdate}
            setOpenForm={setOpenMaintenanceForm}
            setMaintenanceItem={setMaintenanceItem}
          />
        </div>
        {treeImages.length > 0 && (
          <>
            {treeIndex > 0 && (
              <Button
                onClick={() => {
                  if (treeIndex > 0) {
                    setTreeIndex(treeIndex - 1);
                  }
                }}
              >
                {treeIndex > 0 && <AngleLeftIcon />}
              </Button>
            )}
            {treeIndex < treeImages.length - 1 && (
              <Button
                onClick={() => {
                  if (treeIndex < treeImages.length - 1) {
                    setTreeIndex(treeIndex + 1);
                  }
                }}
              >
                <AngleRightIcon />
              </Button>
            )}
            <Card role="treeimages" id={`${tree.name}+${treeIndex}`}>
              <Card.Img
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = process.env.PUBLIC_URL + "/img/project-placeholder.webp";
                }}
                variant="top"
                src={
                  !treeImages.at(treeIndex)
                    ? process.env.PUBLIC_URL + "/img/project-placeholder.webp"
                    : treeImages.at(treeIndex).tree_image
                }
                alt={`${tree.name}+${treeIndex}`}
                className="treeImage"
              />
              <Card.Body className="text-end ">
                <div className=" d-flex align-items-center gap-1">
                  {treeImages.at(treeIndex).timestamp}
                </div>
              </Card.Body>
            </Card>
          </>
        )}
        {leafImages.length > 0 && (
          <>
            {leafIndex < leafImages.length - 1 && (
              <Button
                onClick={() => {
                  if (leafIndex > 0) {
                    setLeafIndex(leafIndex - 1);
                  }
                }}
              >
                <AngleLeftIcon />
              </Button>
            )}
            {treeIndex < treeImages.length - 1 && (
              <Button
                onClick={() => {
                  if (leafIndex < leafImages.length - 1) {
                    setLeafIndex(leafIndex + 1);
                  }
                }}
              >
                <AngleRightIcon />
              </Button>
            )}
            <Card role="leafimages">
              <Card.Img
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = process.env.PUBLIC_URL + "/img/project-placeholder.webp";
                }}
                variant="top"
                src={
                  !leafImages.at(leafIndex)
                    ? process.env.PUBLIC_URL + "/img/project-placeholder.webp"
                    : leafImages?.at(leafIndex).leaf_image
                }
                alt=""
                className="leafImage"
              />
              <Card.Body className="text-end ">
                <div className=" d-flex align-items-center gap-1">
                  {leafImages?.at(leafIndex).timestamp}
                </div>
              </Card.Body>
            </Card>
          </>
        )}
      </div>

      {openForm && (
        <TreeForm
          show={openForm}
          isUpdate={isUpdate}
          formValue={tree}
          setOpenForm={setOpenForm}
          setIsUpdate={setIsUpdate}
        />
      )}
      {openImageForm && (
        <TreeImageForm show={openImageForm} formValue={tree} setOpenForm={setOpenImageForm} />
      )}
      {openMaintenanceForm && (
        <MaintenanceForm
          show={openMaintenanceForm}
          tree={tree}
          setOpenForm={setOpenMaintenanceForm}
          isUpdate={isMaintenanceUpdate}
          setIsUpdate={setIsMaintenanceUpdate}
          maintenanceItem={maintenance}
        />
      )}
    </>
  );
}

export const DetailListItem = ({ title, value }) => {
  const { t } = useTranslation();
  return (
    <li className="list-group-item cursor-pointer">
      <span style={{ fontWeight: "500" }}>{t(`${title}`)}</span>:
      <span className="text-muted ms-1">{value}</span>
    </li>
  );
};
