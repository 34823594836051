import React from "react";
import { Field } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Name() {
  const { t } = useTranslation();
  return (
    <>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>{t("name")}</Form.Label>
        <Field type="text" name="name" className={"form-control mb-3"} />
      </Form.Group>
    </>
  );
}

export default Name;
