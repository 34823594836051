import { useDrag } from "react-dnd";
import { useTranslation } from "react-i18next";
import "bootstrap-daterangepicker/daterangepicker.css";
import React, { useContext, useEffect, useState } from "react";
import { DataScienceContext } from "../context/DataScienceContext";
import {
  CloseOutlineIcon,
  PulseIcon,
  TemperatureIcon,
  MoistureIcon,
  MoreIcon,
} from "@components/icons";
import { Dropdown } from "react-bootstrap";

function Variables() {
  const {
    filterState,
    lYaxis,
    rYaxis,
    setActiveRain,
    activeRain,
    activeMean,
    setActiveMean,
    setLYaxisMean,
    setRYaxisMean,
    setActiveHealthData,
    activeHealthData,
    setDraggableClicked,
    activeSensors,
  } = useContext(DataScienceContext);
  const { hardwareSerial } = filterState;
  const [activeResistance, setActiveResistance] = useState(false);
  const [activeTemperature, setActiveTemperature] = useState(false);
  const [activeSoilMoisture, setActiveSoilMoisture] = useState(false);
  const [activeSoilWaterTension, setActiveSoilWaterTension] = useState(false);
  const [activeDendrometer, setActiveDendrometer] = useState(false);
  const [activeDendrometerDiff, setActiveDendrometerDiff] = useState(false);
  // const [activeResTempComp, setActiveResTempComp] = useState(false);
  const [activeMoistureContent, setActiveMoistureContent] = useState(false);
  let pulseSensor = !activeSensors.every((sensor) => sensor.pulse_sensor == null);
  let soilMoistureSensor = !activeSensors.every((sensor) => sensor.soil_moisture_sensor == null);
  let soilWaterTensionSensor = !activeSensors.every(
    (sensor) => sensor.soil_water_tension_sensor == null
  );
  let dendrometerSensor = !activeSensors.every((sensor) => sensor.dendrometer_sensor == null);

  const { t } = useTranslation();
  useEffect(() => {
    let active = lYaxis && lYaxis[0]?.type;
    if (active == "resistance") {
      setActiveResistance(true);
    } else if (active == "temperature") {
      setActiveTemperature(true);
    } else if (active == "water_soil") {
      setActiveSoilMoisture(true);
    } else if (active == "soil_water_tension") {
      setActiveSoilWaterTension(true);
    } else if (active == "dendrometer") {
      setActiveDendrometer(true);
    } else if (active == "dendrometerdiff") {
      setActiveDendrometerDiff(true);
      // } else if (active == "resisTempComp") {
      //   setActiveResTempComp(true);
    } else if (active == "moisture_content") {
      setActiveMoistureContent(true);
    }
  }, [lYaxis]);

  useEffect(() => {
    let active = rYaxis && rYaxis[0]?.type;
    if (active == "resistance") {
      setActiveResistance(true);
    } else if (active == "temperature") {
      setActiveTemperature(true);
    } else if (active == "water_soil") {
      setActiveSoilMoisture(true);
    } else if (active == "soil_water_tension") {
      setActiveSoilWaterTension(true);
    } else if (active == "dendrometer") {
      setActiveDendrometer(true);
    } else if (active == "dendrometerdiff") {
      setActiveDendrometerDiff(true);
      // } else if (active == "resisTempComp") {
      //   setActiveResTempComp(true);
    } else if (active == "moisture_content") {
      setActiveMoistureContent(true);
    }
  }, [rYaxis]);

  const handleChange = (e) => {
    if (e.target.checked) {
      setActiveRain(true);
    } else if (!e.target.checked) {
      setActiveRain(false);
    }
  };
  const handleHealthDataChange = (e) => {
    if (e.target.checked) {
      setActiveHealthData(true);
    } else if (!e.target.checked) {
      setActiveHealthData(false);
    }
  };
  const handleMeanChange = (e) => {
    if (e.target.checked) {
      setActiveMean(true);
    } else if (!e.target.checked) {
      setActiveMean(false);
      setLYaxisMean([]);
      setRYaxisMean([]);
    }
  };

  return (
    <div
      className="d-flex align-items-start justify-content-between  gap-2 mb-3"
      style={{ flexWrap: "wrap", borderBottom: "1px solid #eee" }}
    >
      <div className="variables">
        <ul className="d-flex  p-0 mb-0" style={{ flexWrap: "wrap" }}>
          {pulseSensor && (
            <List
              type="list"
              name={"resistance"}
              icon={<PulseIcon />}
              active={activeResistance}
              hardwareSerial={hardwareSerial}
              setDraggableClicked={setDraggableClicked}
            />
          )}
          {(pulseSensor || soilMoistureSensor || dendrometerSensor || soilWaterTensionSensor) && (
            <List
              type="list"
              name={"temperature"}
              icon={<TemperatureIcon />}
              active={activeTemperature}
              hardwareSerial={hardwareSerial}
              setDraggableClicked={setDraggableClicked}
            />
          )}{" "}
          {soilMoistureSensor && (
            <List
              type="list"
              name={"water_soil"}
              icon={<PulseIcon />}
              active={activeSoilMoisture}
              hardwareSerial={hardwareSerial}
              setDraggableClicked={setDraggableClicked}
            />
          )}
          {soilWaterTensionSensor && (
            <List
              type="list"
              name={"soil_water_tension"}
              icon={<PulseIcon />}
              active={activeSoilWaterTension}
              hardwareSerial={hardwareSerial}
              setDraggableClicked={setDraggableClicked}
            />
          )}
          {dendrometerSensor && (
            <List
              type="list"
              name={"dendrometer"}
              icon={<PulseIcon />}
              active={activeDendrometer}
              hardwareSerial={hardwareSerial}
              setDraggableClicked={setDraggableClicked}
            />
          )}
          {dendrometerSensor && (
            <List
              type="list"
              name={"dendrometerdiff"}
              icon={<PulseIcon />}
              active={activeDendrometerDiff}
              hardwareSerial={hardwareSerial}
              setDraggableClicked={setDraggableClicked}
            />
          )}
          {/* {pulseSensor && (
            <List
              type="list"
              name={"resis_temp_comp"}
              icon={<TemperatureIcon />}
              active={activeResTempComp}
              hardwareSerial={hardwareSerial}
              setDraggableClicked={setDraggableClicked}
            />
          )} */}
          {pulseSensor && (
            <List
              type="list"
              name={"moisture_content"}
              icon={<MoistureIcon />}
              active={activeMoistureContent}
              hardwareSerial={hardwareSerial}
              setDraggableClicked={setDraggableClicked}
            />
          )}
          <Dropdown>
            <Dropdown.Toggle
              style={{
                height: "100%",
                background: "transparent",
                color: "darkblue",
                border: "navajowhite",
              }}
            >
              <MoreIcon width="30px" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0 shadow">
              <li className="d-flex align-items-center justify-content-between">
                {/* <AgerageIcon />  */}
                {t("mean")}
                <input
                  type="checkbox"
                  className="me-2"
                  onChange={(e) => handleMeanChange(e)}
                  checked={activeMean ? true : false}
                />
              </li>

              <li className="d-flex align-items-center justify-content-between">
                {/* <RainIcon width="14px" /> &nbsp; */}
                {t("rain")}
                <input
                  type="checkbox"
                  className="me-2"
                  onChange={(e) => handleChange(e)}
                  checked={activeRain ? true : false}
                />
              </li>
              <li className="d-flex align-items-center justify-content-between">
                {/* <PlusMedicalIcon width="14px" /> &nbsp; */}
                {t("health_state")}
                <input
                  type="checkbox"
                  className="me-2"
                  onChange={(e) => handleHealthDataChange(e)}
                  checked={activeHealthData ? true : false}
                />
              </li>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
      </div>
    </div>
  );
}

export default Variables;

const List = ({ hardwareSerial, name, type, icon, active, setDraggableClicked }) => {
  const { t } = useTranslation();
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type,
      item: { name },
      canDrag: () => {
        return hardwareSerial.length !== 0 ? true : false;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [hardwareSerial]
  );

  return (
    <>
      {isDragging && ""}
      <li
        data-title="Drag the variable"
        className="variables__item"
        onClick={() => setDraggableClicked(true)}
        ref={drag}
      >
        {icon} {t(name)}
      </li>
      {active && <CloseOutlineIcon title={`Clear ${name}`} />}
    </>
  );
};
