import { Field } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function UserAddress({ formField }) {
  const { zipCode, streetName, houseNumber, city, country } = formField;

  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <Form.Group className="flex-fill ">
          <Form.Label>{t(streetName.label)}</Form.Label>
          <Field
            type="text"
            name={streetName.name}
            className="form-control mb-3"
            // placeholder={t("street_name")}
          />
        </Form.Group>
        <Form.Group className="flex-fill">
          <Form.Label>{t(zipCode.label)}</Form.Label>

          <Field
            type="number"
            name={zipCode.name}
            className="form-control mb-3"
            // placeholder={t("zip_code")}
          />
        </Form.Group>
        <Form.Group className="flex-fill">
          <Form.Label>{t(houseNumber.label)}</Form.Label>
          <Field
            type="number"
            name={houseNumber.name}
            className="form-control mb-3"
            // placeholder={t("house_number")}
            min="0"
          />
        </Form.Group>
      </div>
      <div className="d-flex align-items-center gap-2">
        <Form.Group className="flex-fill">
          <Form.Label htmlFor="">{t(city.label)}</Form.Label>
          <Field
            type="text"
            name={city.name}
            className="form-control "
            // placeholder={t("trunk_circumference")}
          />
        </Form.Group>
        <Form.Group className="flex-fill">
          <Form.Label>{t(country.label)}</Form.Label>
          <Field
            type="text"
            name={country.name}
            className="form-control "
            // placeholder={t("crown_diameter")}
          />
        </Form.Group>
      </div>
    </>
  );
}
