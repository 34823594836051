import React from "react";
import { Field } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function AreaName({ validation }) {
  const { t } = useTranslation();
  return (
    <Form.Group>
      <Form.Label> {t("Area") + "  " + t("name")} *</Form.Label>
      <Field className="form-control" name="name" validate={validation.nameValidation} />
    </Form.Group>
  );
}

export default AreaName;
