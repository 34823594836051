import { Field } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function TreeProfile({ formField }) {
  const { treeCadastreNo, name } = formField;
  const { t } = useTranslation();
  return (
    <div className="d-flex align-item-center w-100 gap-2">
      <Form.Group className="w-50">
        <Form.Label>{t(treeCadastreNo.label)}</Form.Label>
        <Field
          type="text"
          name={treeCadastreNo.name}
          className="form-control "
          // placeholder={t("tree_cadastre_no")}
        />
      </Form.Group>
      <Form.Group className="w-50">
        <Form.Label>{t(name.label)}</Form.Label>
        <Field
          type="text"
          name={name.name}
          className="form-control "
          // placeholder={t("tree") + " " + t("name")}
        />
      </Form.Group>
    </div>
  );
}
