import React, { useState } from "react";
import { useQuery } from "react-query";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { DataScienceContext } from "./context/DataScienceContext";

import GraphArea from "./graph/GraphArea";
import {
  fetchRainDataByDate,
  fetchTreeHealthByDate,
  fetchTSDataLeftByDate,
  fetchTSDataLeftMeanByDate,
} from "@modules/Trees/services/treeCrud";
import EmptyArea from "./components/EmptyArea";
import DropBox from "./components/DropBox";
import { GREEN, YELLOW, RED, GREY, BLUE } from "@helper/colors";

// const useWaitForHardwareSerials = (tree_sensor_pairs) => {
//   const [ready, setReady] = useState(false);
//
//   useEffect(() => {
//     function checkSerials() {
//       if (tree_sensor_pairs.every((tsp) => tsp.hardware_serial !== undefined)) {
//         setReady(true);
//       } else {
//         setTimeout(checkSerials, 1000); // Check every second
//       }
//     }
//
//     checkSerials();
//   }, [tree_sensor_pairs]);
//
//   return ready;
// };

function GraphContainer() {
  const [rainData, setRainData] = useState([]);
  const query = new URLSearchParams(useLocation().search);
  var tid = query.get("trees");
  const id = query.get("areas");
  // const serials = query.get("sensors");
  const tsid = query.get("treeSensors");
  var serials = tsid;
  if (!tid) {
    if (tsid) {
      tid = tsid
        .split(",")
        .map((tsp) => {
          return tsp.split(" ")[0];
        })
        .join(",");
    }
  }
  if (tsid) {
    serials = tsid
      .split(",")
      .map((tsp) => {
        return tsp.split(" ")[1];
      })
      .join(",");
  }

  const {
    filterState,
    setLYaxis,
    setRYaxis,
    setLYaxisMean,
    setRYaxisMean,
    startDate,
    endDate,
    dragItemLeft,
    dragItemRight,
    positionLeft,
    positionRight,
    activeDate,
    setHealthData,
    lineColor,
    activeMean,
    activeHealthData,
    activeRain,
  } = useContext(DataScienceContext);
  let { hardwareSerial, tree_sensor_pairs_tree, tree_sensor_pairs, areasId } = filterState;
  // const tree_sensor_pairs = tree_sensor_pairs_tree;
  tree_sensor_pairs = tree_sensor_pairs
    .map((s) => tree_sensor_pairs_tree.find((t) => t.tree === +s.tree && t.sensor === +s.sensor))
    .filter(Boolean);

  const { isLoading: isRainLoading, refetch: refetchRain } = _getRain({
    id,
    startDate,
    endDate,
    activeRain,
    setRainData,
    areasId,
  });

  const { isLoading: isHealthDataLoading, refetch: refetchHealth } = _getHealthData({
    hardwareSerial,
    setHealthData,
    startDate,
    endDate,
    activeHealthData,
  });

  useEffect(() => {
    if (areasId.length == 1 && activeRain == true) {
      refetchRain();
    } else {
      setRainData([]);
    }
  }, [activeDate, activeRain]);
  useEffect(() => {
    if (hardwareSerial.length == 1 && activeHealthData) {
      refetchHealth();
    } else {
      setHealthData([]);
    }
  }, [activeDate, activeHealthData, hardwareSerial]);

  //fetching::begin

  // const ready = useWaitForHardwareSerials(tree_sensor_pairs);
  let checkInitialEmpty = true;
  let checkLoading = false;
  // If hardware_serials are not ready yet, don't run _getTsDataLeft
  // var ready = false;
  // if (tree_sensor_pairs.every((item) => item.hardware_serial != undefined)) {
  //   ready = true;
  // }

  // if (ready) {
  const {
    isLoading: isLeftLoading,
    isFetching: isLeftFetching,
    refetch: refetchLeft,
  } = _getTsData({
    tree_sensor_pairs,
    setLYaxis,
    positionLeft,
    dragItemLeft,
    startDate,
    endDate,
    lineColor,
  });
  const {
    isLoading: isRightLoading,
    refetch: refetchRight,
    isFetching: isRightFetching,
  } = _getTsData({
    tree_sensor_pairs,
    setLYaxis: setRYaxis,
    dragItemLeft: dragItemRight,
    postionLeft: positionRight,
    startDate,
    endDate,
    lineColor,
  });

  ///mean
  const {
    isLoading: isLeftMeanLoading,
    isFetching: isLeftMeanFetching,
    refetch: refetchMeanLeft,
  } = _getTsDataMean({
    tree_sensor_pairs,
    setLYaxisMean,
    positionLeft,
    dragItemLeft,
    startDate,
    endDate,
    lineColor,
    activeMean,
  });
  const {
    isLoading: isRightMeanLoading,
    isFetching: isRightMeanFetching,
    refetch: refetchMeanRight,
  } = _getTsDataMean({
    tree_sensor_pairs,
    setLYaxisMean: setRYaxisMean,
    dragItemLeft: dragItemRight,
    positionLeft: positionRight,
    startDate,
    endDate,
    lineColor,
    activeMean,
  });

  // refetch:begin;
  useEffect(() => {
    if (tree_sensor_pairs.length > 0 && positionLeft == true) {
      refetchLeft();
    }
  }, [activeDate, tsid, dragItemLeft]);

  useEffect(() => {
    if (tree_sensor_pairs.length > 0 && positionRight == true) {
      refetchRight();
    }
  }, [activeDate, tsid, dragItemRight]);

  useEffect(() => {
    if (tree_sensor_pairs.length > 0 && positionLeft == true && activeMean === true) {
      refetchMeanLeft();
    }
  }, [activeDate, tsid, dragItemLeft]);

  useEffect(() => {
    if (tree_sensor_pairs.length > 0 && positionRight == true && activeMean === true) {
      refetchMeanRight();
    }
  }, [activeDate, tsid, dragItemRight]);

  // refetch::end

  checkInitialEmpty = !id || !tid || !serials || (!dragItemRight && !dragItemLeft) ? true : false;

  checkLoading =
    isRightLoading ||
    isLeftLoading ||
    isLeftFetching ||
    isRightFetching ||
    isLeftMeanLoading ||
    isLeftMeanFetching ||
    isRightMeanLoading ||
    isRightMeanFetching ||
    isHealthDataLoading ||
    isRainLoading
      ? true
      : false;
  // }
  return (
    <div className="position-relative ds graph">
      {checkInitialEmpty ? (
        <>
          <DropBox />
          <EmptyArea tid={tid} dragItem={dragItemLeft} id={id} serial={serials} />
        </>
      ) : (
        <>
          {checkLoading && <Spin />}
          <>
            <DropBox />
            {<GraphArea checkLoading={checkLoading} rainData={rainData} />}
          </>
        </>
      )}
    </div>
  );
}

export default GraphContainer;

const Spin = () => {
  return (
    <div className="spin">
      <Spinner animation="border" variant="primary" style={{ width: "3rem", height: "3rem" }} />
    </div>
  );
};

// A.Resistance and Mean
function _getTsData({
  tree_sensor_pairs,
  dragItemLeft,
  positionLeft,
  startDate,
  endDate,
  lineColor,
  setLYaxis,
}) {
  const tsps = tree_sensor_pairs.map((tsp) => {
    return `${tsp.tree}+${tsp.sensor}`;
  });
  const tree_sensors_joined = tsps.join(",");
  return useQuery(
    `${dragItemLeft}+${tree_sensors_joined}`,
    () =>
      fetchTSDataLeftByDate({
        tsps: tree_sensors_joined,
        startDate,
        endDate,
        dragItemLeft,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled:
        [
          "resistance",
          "temperature",
          "soil_moisture",
          "soil_water_tension",
          "resis_temp_comp",
          "moisture_content",
        ].includes(dragItemLeft) && positionLeft,
      onSuccess: (data) => {
        const { x, y } = data;
        let result = [];
        tree_sensor_pairs?.map((tsp, i) => {
          if (x[tsp.tree] === undefined) return;
          x[tsp.tree][tsp.sensor] &&
            result.push({
              name: (tsp.tree_name ?? tsp.tree) + " " + (tsp.sensor_name ?? tsp.sensor),
              type: "line",
              label: dragItemLeft,
              tsp: tsp,
              color: lineColor[tsp.tree + " " + tsp.sensor],
              data: x[tsp.tree][tsp.sensor][dragItemLeft]
                ?.map((value, index) => {
                  if (y[tsp.tree][tsp.sensor][dragItemLeft][index]) {
                    return [
                      {
                        type: dragItemLeft,
                        value: [value, y[tsp.tree][tsp.sensor][dragItemLeft][index]],
                        color: lineColor[tsp.tree + " " + tsp.sensor],
                        tsp: tsp,
                      },
                    ];
                  }
                })
                .flat(),
            });
        });
        setLYaxis(result);
        return;
      },
    }
  );
}
function _getTsDataMean({
  tree_sensor_pairs,
  dragItemLeft,
  positionLeft,
  startDate,
  endDate,
  lineColor,
  activeMean,
  setLYaxisMean,
}) {
  const tsps = tree_sensor_pairs.map((tsp) => {
    return tsp.tree + "+" + tsp.sensor;
  });
  return useQuery(
    `${dragItemLeft}-mean`,
    () =>
      fetchTSDataLeftMeanByDate({
        tsps,
        startDate,
        endDate,
        dragItemLeft,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: activeMean && positionLeft,
      onSuccess: (data) => {
        const { x, y } = data;
        let result = [];
        tree_sensor_pairs?.map((tsp, i) => {
          if (x[tsp.tree] === undefined) return;
          x[tsp.tree][tsp.sensor] &&
            result.push({
              name: (tsp.tree_name ?? tsp.tree) + " " + (tsp.sensor_name ?? tsp.sensor),
              type: "line",
              label: dragItemLeft,
              color: lineColor[tsp.tree + " " + tsp.sensor] + 50,
              data: x[tsp.tree][tsp.sensor][dragItemLeft]
                ?.map((value, index) => {
                  if (y[tsp.tree][tsp.sensor][dragItemLeft][index]) {
                    return [
                      {
                        type: dragItemLeft + "mean",
                        value: [value, y[tsp.tree][tsp.sensor][dragItemLeft][index]],
                        color: lineColor[tsp.tree + " " + tsp.sensor] + 50,
                      },
                    ];
                  }
                })
                .flat(),
            });
        });
        setLYaxisMean(result);
        return;
      },
    }
  );
}
// D.Sensor health
function _getHealthData({ hardwareSerial, setHealthData, startDate, endDate, activeHealthData }) {
  return useQuery(
    "healthState",
    () => fetchTreeHealthByDate({ hardwareSerial, startDate, endDate }),
    {
      refetchOnWindowFocus: false,
      enabled: !!activeHealthData,
      onSuccess: (data) => {
        let result = [];
        let b = data?.time?.map((value, i) => {
          let color = "";
          if (data.states[i] == 0) {
            color = GREEN;
          }
          if (data.states[i] == 1) {
            color = YELLOW;
          }
          if (data.states[i] == 2) {
            color = RED;
          }
          if (data.states[i] == 3) {
            color = GREY;
          }
          if (data.states[i] == 4) {
            color = BLUE;
          }
          return [
            {
              value: [value, 1],
              itemStyle: {
                color: color,
              },
            },
          ];
        });

        result.push({
          name: "health",
          type: "bar",
          yAxisIndex: 2,
          barCategoryGap: -50,
          large: true,
          barWidth: 50,
          data: b ? b.flat() : [],
        });
        setHealthData(result);
      },
    }
  );
}

// E.Rain data
function _getRain({ id, activeRain, setRainData, startDate, endDate, areasId }) {
  return useQuery("rain", () => fetchRainDataByDate({ id, startDate, endDate }), {
    refetchOnWindowFocus: false,
    enabled: activeRain && areasId.length == 1,
    onSuccess: (data) => {
      const { rain } = data;
      let res = [];
      const b = rain?.x?.map((value, i) => {
        return [
          {
            value: [value, rain?.y[i]],
            color: "#388090",
          },
        ];
      });
      res.push({
        name: "rain",
        type: "line",
        smooth: true,
        showSymbol: false,
        yAxisIndex: 3,
        barCategoryGap: -50,
        color: "#388090",
        areaStyle: { color: "#388090" },
        data: b ? b.flat() : [],
      });
      activeRain && setRainData(res);
    },
  });
}
