import moment from "moment";
import React, { useContext, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { DataScienceContext } from "../context/DataScienceContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LineHorizontal1Icon, MoreHorizontalOutlineIcon } from "@components/icons";

//Chart style
const style = {
  height: "70vh",
  width: "100%",
};

//Chart options
function GraphArea({ rainData }) {
  const {
    lYaxis,
    rYaxis,
    lYaxisMean,
    rYaxisMean,
    healthData,
    dragItemLeft,
    dragItemRight,
    activeRain,
  } = useContext(DataScienceContext);
  const { t } = useTranslation();
  const [showHideLeftData, setHideLeftData] = useState(false);
  const [showHideRightData, setHideRightData] = useState(false);
  const [option, setOption] = useState({
    toolbox: {
      show: true,
      bottom: 50,
      right: 5,
      itemSize: 16,
      zlevel: 10,
      orient: "vertical",
      feature: {
        dataZoom: {
          // yAxisIndex: "none",
        },
        restore: {},
        saveAsImage: {},
      },
    },
    grid: {
      bottom: 50,
      left: 40,
      right: "6%",
      top: 10,
      containLabel: true,
    },

    tooltip: {
      trigger: "item",
      backgroundColor: "#000",
      padding: 15,
      borderWidth: 0,
      textStyle: {
        color: "#fff",
        fontSize: 13,
      },
      formatter: function (params) {
        let labels = [];
        if (Object.prototype.toString.call(params) === "[object Array]") {
          labels = params.map((item) => {
            let type = item ? item.data.type : "";
            if (type == "dendrometerdiff") {
              type += ` ${item.data.tsp.hardware_serial.slice(-1)} - 1`;
            }
            return (
              `<tr>` +
              `<td class="px-2 " style='color:${item?.data.color}'>` +
              item?.axisValueLabel +
              `</td>` +
              `<td class="px-2 " style='color:${item?.data.color}'>` +
              item?.seriesName +
              `</td>` +
              `<td class="px-2" style='color:${item?.data.color}'>` +
              type +
              `</td>` +
              `<td class="px-2" style='color:${item?.data.color}'>` +
              item?.value[1] +
              `</td>` +
              `</tr>`
            );
          });
        }
        return (
          `<table class="w-100">` +
          `<thead>` +
          `<tr>` +
          `<td class="px-2" >` +
          "Date" +
          `</td>` +
          `<td class="px-2" >` +
          "Tree & Sensor" +
          `</td>` +
          `<td class="px-2">` +
          "Type" +
          `</td>` +
          `<td class="px-2">` +
          "Value" +
          `</td>` +
          `</tr>` +
          `<thead>` +
          `<tbody>` +
          labels +
          `</tbody>` +
          `</table>`
        );
      },
    },
    dataZoom: [
      {
        type: "inside",
        realtime: true,
        start: 0,
        end: 100,
      },
    ],
  });
  useEffect(() => {
    setOption((prev) => ({
      ...prev,
      grid: {
        bottom: 50,
        left: 40,
        right: activeRain ? "12%" : "8%",
        top: 30,
        containLabel: true,
      },
      xAxis: [
        {
          type: "time",

          axisPointer: {
            show: true,
            type: "line",
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            interval: "0",
            lineStyle: {
              color: ["#F5F5F5"],
            },
          },
          axisLabel: {
            hideOverlap: true,
            formatter: function (value) {
              return moment.utc(value).format("DD.MM.YYYY HH:mm");
            },
          },
          offset: 10,
        },
      ],
      yAxis: [
        {
          show: dragItemLeft ? true : false,
          type: "value",

          name:
            dragItemLeft == "resistance"
              ? t(dragItemLeft) + " in kΩ"
              : dragItemLeft == "temperature"
              ? t(dragItemLeft) + " in °C"
              : dragItemLeft == "water_soil"
              ? t(dragItemLeft) + " in %"
              : dragItemLeft == "soil_water_tension"
              ? t(dragItemLeft) + " in kPa"
              : dragItemLeft == "dendrometer"
              ? t(dragItemLeft) + " in µm"
              : t(dragItemLeft),
          axisTick: {
            length: 5,
          },
          nameRotate: 90,
          nameGap: 45,
          offset: 8,
          nameLocation: "center",
          nameTextStyle: {
            verticalAlign: "middle",
            padding: [0, 0, 20, 30],
          },
        },
        {
          show: dragItemRight ? true : false,
          type: "value",
          name:
            dragItemRight == "resistance"
              ? t(dragItemRight) + " in KΩ"
              : dragItemRight == "temperature"
              ? t(dragItemRight) + " in °C"
              : dragItemRight == "water_soil"
              ? t(dragItemRight) + " in %"
              : dragItemRight == "soil_water_tension"
              ? t(dragItemRight) + " in kPa"
              : dragItemRight == "dendrometer"
              ? t(dragItemRight) + " in µm"
              : t(dragItemRight),
          axisTick: {
            length: 5,
          },

          nameRotate: 90,
          nameGap: 45,
          nameLocation: "center",
          nameTextStyle: {
            verticalAlign: "middle",
            padding: [0, 0, 20, 30],
          },
        },
        {
          boundaryGap: ["0%", "5%"],
          show: false,
          type: "value",
          name: "health",
        },
        {
          show: activeRain ? true : false,
          name: "Rainfall(mm)",
          alignTicks: true,
          type: "value",
          offset: dragItemRight && 80,
          nameRotate: 90,
          nameGap: 45,
          nameLocation: "center",
          nameTextStyle: {
            verticalAlign: "middle",
            padding: [0, 0, 20, 30],
          },
        },
      ],
      series: [],
    }));
  }, [dragItemLeft, dragItemRight, activeRain]);

  useEffect(() => {
    const leftDataMean = lYaxisMean?.map((data) => {
      return {
        name: data.name,
        yAxisIndex: 0,
        smooth: true,
        type: "line",
        showSymbol: false,
        lineStyle: {
          width: 2,
          color: data ? data.color : "transparent",
          type: "solid",
        },
        data: data.data,
      };
    });
    const rightDataMean = rYaxisMean?.map((data) => {
      return {
        name: data.name,
        yAxisIndex: 1,
        smooth: true,
        type: "line",
        showSymbol: false,
        style: {
          lineDash: [2],
        },
        lineStyle: {
          width: 2,
          color: data ? data.color : "transparent",
          type: "dotted",
          dashOffset: 3,
        },
        data: data.data,
      };
    });
    const leftData = lYaxis?.map((data) => {
      return {
        name: data.name,
        smooth: true,
        type: "line",
        yAxisIndex: 0,
        showSymbol: false,
        lineStyle: {
          width: 2,
          color: data.color,
          opacity: showHideLeftData ? 0 : 1,
          type: "solid",
        },
        data: data.data,
      };
    });
    const rightData = rYaxis?.map((data) => {
      return {
        name: data.name,
        yAxisIndex: 1,
        smooth: true,
        type: "line",
        showSymbol: false,
        style: {
          lineDash: [2],
        },
        lineStyle: {
          width: 2,
          color: data.color,
          opacity: showHideRightData ? 0 : 1,
          type: "dotted",
          dashOffset: 3,
        },
        data: data.data,
      };
    });

    setOption((prev) => ({
      ...prev,
      series: [leftData, leftDataMean, rightData, rightDataMean, healthData, rainData].flat(),
    }));
  }, [
    lYaxisMean,
    rYaxisMean,
    lYaxis,
    rYaxis,
    healthData,
    rainData,
    showHideRightData,
    showHideLeftData,
  ]);

  const hideLeftData = () => {
    setHideLeftData(!showHideLeftData);
  };
  const hideRightData = () => {
    setHideRightData(!showHideRightData);
  };

  return (
    <>
      <div className=" d-flex justify-content-center  gap-2">
        {dragItemLeft && (
          <div
            className="text-uppercase"
            style={{
              cursor: "pointer",
              zIndex: 99,
              fontSize: "12px",
              background: "rgba(90, 208, 210, 0.098039216)",
              padding: "5px 10px",
              borderRadius: "3px",
            }}
            onClick={hideLeftData}
          >
            <LineHorizontal1Icon showHideLeftData={showHideLeftData} /> {t(dragItemLeft)}
          </div>
        )}
        {dragItemRight && (
          <div
            className="text-uppercase"
            onClick={hideRightData}
            style={{
              cursor: "pointer",
              zIndex: 99,
              fontSize: "12px",
              background: "rgba(90, 208, 210, 0.098039216)",
              padding: "5px 10px",
              borderRadius: "3px",
            }}
          >
            <MoreHorizontalOutlineIcon showHideRightData={showHideRightData} />
            {t(dragItemRight)}
          </div>
        )}
      </div>

      <ReactEcharts option={option} style={style} notMerge />
    </>
  );
}

export default GraphArea;
