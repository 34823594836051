import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import useChoices from "@hooks/useChoices";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Spinner from "@components/Spinner/Spinner";
import ServerError from "@components/Error/ServerError";
import PageNotFound from "@components/Error/PageNotFound";
import ExportSensor from "@modules/ExportSensor/ExportSensor";
import { getSensor, sensorPlot, installationPlot, getLastSend } from "../services/sensorCrud";
import MobileBreadcrumb from "@components/Breadcrumb/MobileBreadcrumb";
import SensorChart from "@modules/Trees/components/SensorGraph/SensorChart";
import SensorForm from "./Form/SensorForm";
import { GREEN, RED } from "@helper/colors";

const style = {
  height: "260px",
  width: "100%",
};

export function SensorPlot({ sensor, plotType, setPlotType, installation }) {
  const [plot, setPlot] = useState();
  const [plotTitle, setPlotTitle] = useState("");
  const { t } = useTranslation();
  const { isLoading: isPlotLoading } = useQuery(
    ["sensorplot", { sensor }],
    () => (installation ? installationPlot(installation) : sensorPlot(sensor)),
    {
      enabled: !!sensor,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const { x, y } = data;
        let sensorType = "resistance";
        let plotTitle = "Resistance in kΩ";
        sensor.soil_moisture_sensor
          ? ((sensorType = "soilMoisture"), (plotTitle = "Soil Moisture in %"))
          : sensor.soil_water_tension_sensor
          ? ((sensorType = "soilWaterTension"), (plotTitle = "Soil Water Tension in kPa"))
          : sensor.dendrometer_sensor
          ? ((sensorType = "dendrometer"), (plotTitle = "Dendrometer in µm"))
          : (sensorType = "resistance");
        setPlotType(sensorType);
        setPlotTitle(plotTitle);

        let d;
        if (sensorType == "resistance") {
          // d = x[sensor.hardware_serial]?.resistance?.map((value, index) => {
          //   if (y[sensor.hardware_serial].resistance[index]) {
          //     return [value, y[sensor.hardware_serial].resistance[index]];
          //   }
          // });
          const mainData =
            x[sensor.id]?.resistance?.map((value, index) => [
              value,
              y[sensor.id].resistance[index],
            ]) || [];
          // const resis70mcData =
          //   x[sensor.hardware_serial]?.resistance?.map((value, index) => [
          //     value,
          //     y[sensor.hardware_serial].resis_70mc[index],
          //   ]) || [];
          // const resis50mcData =
          //   x[sensor.hardware_serial]?.resistance?.map((value, index) => [
          //     value,
          //     y[sensor.hardware_serial].resis_50mc[index],
          //   ]) || [];
          // console.log(mainData);

          d = [
            { data: mainData, name: "resistance", type: "line", showSymbol: false }, // Assuming blue for the main resistance line
            // {
            //   data: resis70mcData,
            //   color: GREEN,
            //   name: "resistance for 70%",
            //   type: "line",
            //   showSymbol: false,
            // },
            // {
            //   data: resis50mcData,
            //   color: RED,
            //   name: "resistance for 50%",
            //   type: "line",
            //   showSymbol: false,
            // },
          ];
        }
        if (sensorType == "soilMoisture") {
          const mainData =
            x[sensor.id]?.water_soil?.map((value, index) => [
              value,
              y[sensor.id].water_soil[index],
            ]) || [];
          d = [
            {
              data: mainData,
              color: "blue",
              name: "soil moisture",
              type: "line",
              showSymbol: false,
            }, // Assuming blue for the main resistance line
          ];
        }
        if (sensorType == "soilWaterTension") {
          const mainData =
            x[sensor.id]?.soil_water_tension?.map((value, index) => [
              value,
              y[sensor.id].soil_water_tension[index],
            ]) || [];
          d = [
            {
              data: mainData,
              color: "blue",
              name: "soil water tension",
              type: "line",
              showSymbol: false,
            }, // Assuming blue for the main resistance line
          ];
        }
        if (sensorType == "dendrometer") {
          const mainData =
            x[sensor.id]?.dendrometer?.map((value, index) => [
              value,
              y[sensor.id].dendrometer[index],
            ]) || [];
          d = [
            {
              data: mainData,
              color: "blue",
              name: "dendrometer",
              type: "line",
              showSymbol: false,
            }, // Assuming blue for the main resistance line
          ];
        }
        const plotNew = d ? [...d] : null;
        setPlot(plotNew);
      },
    }
  );
  return (
    <>
      {isPlotLoading ? (
        <Spin />
      ) : plot ? (
        <SensorChart plot={plot} style={style} type={plotType} plotTitle={plotTitle} />
      ) : (
        <div className=" bg-light text-center" style={{ height: "15vh" }}>
          <img name="empty_project" alt="" width="80" src="/empty_project.svg" />
          <h6 className="my-4">{t("sensor_details.no_data_found")}</h6>
        </div>
      )}
    </>
  );
}

export default function SensorDetail() {
  const [isUpdate, setIsUpdate] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [plotType, setPlotType] = useState("resistance");
  const { mobile } = useSelector((state) => state.mobile);
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("selectedSensor");
  const [lastSend, setLastSend] = useState("");
  const [lastSendValue, setLastSendValue] = useState("");
  const { t } = useTranslation();
  //get sensor detail
  const {
    data: sensor,
    isLoading,
    isError,
    error,
  } = useQuery(["sensors", { id }], () => getSensor(id), {
    refetchOnWindowFocus: false,
    retry: 3, // number of times to retry the request before giving up
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // delay between retries in milliseconds
    // retry: false,
    enabled: !!id,
  });

  useQuery(["last-sent", { id }], () => getLastSend(id), {
    refetchInterval: 5000, // every 5 seconds
    refetchOnWindowFocus: false,
    retry: 3, // number of times to retry the request before giving up
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // delay between retries in milliseconds
    // retry: false,
    enabled: !!id,
    onSuccess: (data) => {
      setLastSend(data._time ? data._time[0] : 0);
      setLastSendValue(
        data.resistance
          ? data.resistance[0]
          : data.water_soil
          ? data.water_soil[0]
          : data.soil_water_tension
          ? data.soil_water_tension[0]
          : data.dendromeere
          ? data.dendrometer[0]
          : ""
      );
    },
  });

  const handleEdit = () => {
    setIsUpdate(true);
    setOpenForm(true);
  };

  const [healthState] = useChoices("health_state");
  const [sensorState] = useChoices("sensor_state");

  if (isLoading) return <Spinner height={"80vh"} />;
  if (isError && error.response.status === 404) {
    return <PageNotFound />;
  }
  if (isError) {
    console.log("ERROR", error);
    return <ServerError />;
  }

  const now = new Date();
  const hours_passed = (now - new Date(lastSend)) / (1000 * 60 * 60);
  let isSending = false;
  if (hours_passed < 3) {
    isSending = true;
  }
  let isSendingObj = localStorage.getItem("isSending");
  if (isSendingObj == undefined) {
    isSendingObj = {};
  }

  isSendingObj[sensor.id] = isSending;

  localStorage.setItem("lastSend", isSending);

  return (
    <>
      {mobile && (
        <>
          <MobileBreadcrumb
            editButton={
              <Button data-testid="sensor-detail-edit" onClick={() => handleEdit()}>
                {t("edit")}
              </Button>
            }
          />
        </>
      )}
      <div>
        <div
          className="top-0  position-sticky d-flex justify-content-end align-items-center"
          style={{ zIndex: "99", background: "white", padding: "0 12px", height: "55px" }}
        >
          {
            // plot && (
            <div className="d-flex gap-2 align-items-center">
              <ExportSensor serials={[sensor.id]} name={sensor.name} sensors={[sensor]} />
            </div>
          }
        </div>
        <div style={{ background: "white", padding: "0 12px" }}>
          <div>
            <h4 className="mb-3" data-testid="test-sensor-name">
              {sensor.name}
            </h4>
          </div>
          <div className="notes mb-4">
            <ul className="p-0 mb-0 list-unstyled">
              <li className="pb-2">
                <span style={{ fontWeight: "500" }}>{t("sensor_name")}:</span> {sensor.name}
              </li>
              <li className="pb-2">
                <span style={{ fontWeight: "500" }}>
                  {sensor.network_type == "LW" ? t("hardware_serial") : t("imei")}:
                </span>{" "}
                {sensor.hardware_serial}
              </li>
              {sensor.pulse_sensor !== null && (
                <>
                  <li className="pb-2">
                    <span style={{ fontWeight: "500" }}>{t("threshold")}</span>:{" "}
                    <i> {sensor?.threshold}</i>
                  </li>
                </>
              )}
              <li className="pb-2">
                <span style={{ fontWeight: "500" }}>{t("last_battery")}: </span>
                {sensor.battery ? sensor.battery + "%" : t("unknown")}
              </li>
              <li className="pb-2">
                <span style={{ fontWeight: "500" }}>{t("health_state")}</span>:{" "}
                {
                  {
                    0: healthState && healthState[0].value,
                    1: healthState && healthState[1].value,
                    2: healthState && healthState[2].value,
                    3: healthState && healthState[3].value,
                    4: healthState && healthState[4].value,
                  }[sensor.health_state]
                }
              </li>
              <li className="pb-2">
                <span style={{ fontWeight: "500" }}>{t("sensor_state")}</span>:{" "}
                {
                  {
                    // TODO: if actual last sent is within the range, set it to working
                    0: sensorState && sensorState[0].value,
                    1: sensorState && sensorState[1].value,
                    2: sensorState && sensorState[2].value,
                    3: isSending
                      ? sensorState && sensorState[0].value
                      : sensorState && sensorState[3].value,
                    4: sensorState && sensorState[4].value,
                  }[sensor.sensor_state]
                }
              </li>
              <li className="pb-2">
                <span style={{ fontWeight: "500" }}>{t("last_send")}</span>:{" "}
                {moment(new Date(lastSend)).format("DD.MM.YYYY HH:mm")}
              </li>
              <li className="pb-2">
                <span style={{ fontWeight: "500" }}>{t("last_value")}</span>: {lastSendValue}
              </li>
              <li>
                <br />
                <b>{t("graph")}:</b>
              </li>
            </ul>
            <SensorPlot sensor={sensor} plotType={plotType} setPlotType={setPlotType} />
          </div>
        </div>
      </div>
      {openForm && (
        <SensorForm
          isUpdate={isUpdate}
          detail={sensor}
          setIsUpdate={setIsUpdate}
          show={openForm}
          setOpenForm={setOpenForm}
        />
      )}
    </>
  );
}

const Spin = () => {
  return (
    <div className="spin">
      <Spinner animation="border" variant="primary" style={{ width: "3rem", height: "3rem" }} />
    </div>
  );
};
